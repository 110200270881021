import { useGlobalSettings } from '@/context/global-settings-provider';

/**
 * This utility hook returns a tabIndex attr for an element based on if we are
 * on the public app version or not. This allows us to make non-interactive
 * items focussable on a kiosk so we can navigate using the keypad.
 *
 * Note: This basic solution is meant to be temporary and should be replaced
 * with a more flexible system that allows for using the up/down arrows when
 * it would make sense and would allow for custom messages per component.
 */
export function useGetKeypadNavAttrs(): { tabIndex?: 0 } {
  const { isPublic } = useGlobalSettings();
  return isPublic ? {} : { tabIndex: 0 };
}
