import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { useGetKeypadNavAttrs } from '@/hooks/use-get-keypad-nav-attrs';
import { setInnerHtml } from '@/lib/setInnerHtml';
import type { ContentComponentProps } from '@/types/app';

import type { Text as TextType } from '../../types/content-schema';

import css from './text.module.scss';

export type TextProps = ContentComponentProps<TextType>;

export const Text: FC<TextProps> = ({ className, translationKey = '' }) => {
  const classes = classnames(css.root, className, 'text');
  const { t } = useTranslation();
  const attrs = useGetKeypadNavAttrs();
  // @ts-expect-error: translationKey is dynamic
  const html = t(translationKey, { interpolation: { escapeValue: true } });

  return (
    <div className={classes} {...attrs} {...setInnerHtml(html as string)} />
  );
};
